import React from 'react';

import { wrapper, grid, container, help, hasError } from './order-result.module.scss';
import { ISection } from '../../models/section.model';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import AnimatedCheckmarkOutlined from '../atoms/animated-checkmark-outlined';
import Info from '../organisms/info';
import Markdown from '../hoc/markdown';

export interface ISectionOrderResultProps {
    section: ISection;
}

export default function OrderResult({ section }: ISectionOrderResultProps) {
    const t = useTranslations('OrderResult');
    const { sectionId, content, css, style } = section;
    const [titleSuccess, textSuccess, helpTextSuccess, titleError, textError, helpTextError] =
        content.texts;
    const [buttonSuccess, buttonError] = content.buttons;

    const search = typeof window !== 'undefined' ? window.location.search : '';
    const urlSearchParams = new URLSearchParams(search);
    const isError = urlSearchParams.get('error');
    const orderId = urlSearchParams.get('orderId');

    const title = isError ? titleError : titleSuccess;
    const text = isError ? textError : textSuccess;
    const helpText = isError ? helpTextError : helpTextSuccess;
    const button = isError ? buttonError : buttonSuccess;

    return (
        <Section
            sectionId={sectionId}
            className={wrapper}
            classes={{ container: grid }}
            css={css}
            style={style}
        >
            <Info
                className={`${container} ${isError ? hasError : ''}`}
                title={title}
                button={button}
            >
                {({ checkmarkClassName, descriptionClassName }) => (
                    <>
                        {!isError && <AnimatedCheckmarkOutlined className={checkmarkClassName} />}
                        <div className={descriptionClassName}>
                            <p>
                                {t.received} {orderId && <strong>{orderId}</strong>}
                            </p>
                            {text && <Markdown>{text}</Markdown>}
                        </div>
                        {helpText && <Markdown className={help}>{helpText}</Markdown>}
                    </>
                )}
            </Info>
        </Section>
    );
}
